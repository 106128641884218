import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { config } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';
import { QueryLibraryContext } from 'app/features/explore/QueryLibrary/QueryLibraryContext';
import { QueryActionButton, QueryActionButtonProps } from 'app/features/explore/QueryLibrary/types';

import { AddToQueryLibraryModal } from './AddToQueryLibraryModal';
import {
  queryLibraryTrackAddQueryOpen,
  queryLibraryTrackOpen,
  queryLibraryTrackQueryAction,
} from './QueryLibraryAnalyticsEvents';
import { QueryLibraryDrawer } from './QueryLibraryDrawer';
import { SaveQueryButton } from './SaveQueryButton';

export function QueryLibraryContextProvider({ children }: PropsWithChildren) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [activeDatasources, setActiveDatasources] = useState<string[]>([]);
  const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState<boolean>(false);
  const [activeQuery, setActiveQuery] = useState<DataQuery | undefined>(undefined);
  const [queryActionButton, setQueryActionButton] = useState<QueryActionButton | undefined>(undefined);
  const [context, setContext] = useState('unknown');
  const [onSave, setOnSave] = useState<(() => void) | undefined>(undefined);

  const openDrawer = useCallback(
    (datasourceFilters: string[], queryActionButton: QueryActionButton, options?: { context?: string }) => {
      setActiveDatasources(datasourceFilters);
      // Because the queryActionButton can be a function component it would be called as a callback if just passed in.
      setQueryActionButton(() => queryActionButton);
      setIsDrawerOpen(true);
      setContext(options?.context || 'unknown');
      queryLibraryTrackOpen(options?.context);
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setActiveDatasources([]);
    setQueryActionButton(undefined);
    setIsDrawerOpen(false);
  }, []);

  const openAddQueryModal = useCallback((query: DataQuery, options?: { onSave?: () => void; context?: string }) => {
    setActiveQuery(query);
    setIsAddQueryModalOpen(true);
    setContext(options?.context || 'unknown');
    setOnSave(options?.onSave);
    queryLibraryTrackAddQueryOpen(options?.context);
  }, []);

  const closeAddQueryModal = useCallback(() => {
    setActiveQuery(undefined);
    setIsAddQueryModalOpen(false);
    setOnSave(undefined);
  }, []);

  // We wrap the action button one time to add the closeDrawer behaviour. This way whoever injects the action button
  // does not need to remember to do it nor the query table inside that renders it needs to know about the drawer.
  const finalActionButton = useMemo(() => {
    if (!queryActionButton) {
      return queryActionButton;
    }
    return (props: QueryActionButtonProps) => {
      const QButton = queryActionButton;
      return (
        <QButton
          {...props}
          onClick={() => {
            props.onClick();
            closeDrawer();
            queryLibraryTrackQueryAction(props.queries[0]?.datasource?.type || 'unknown', context);
          }}
        />
      );
    };
  }, [closeDrawer, queryActionButton, context]);

  const contextVal = useMemo(
    () => ({
      isDrawerOpen,
      openDrawer,
      closeDrawer,
      openAddQueryModal,
      closeAddQueryModal,
      renderSaveQueryButton: (query: DataQuery) => <SaveQueryButton query={query} />,
      queryLibraryEnabled: Boolean(config.featureToggles.queryLibrary),
    }),
    [isDrawerOpen, openDrawer, closeDrawer, openAddQueryModal, closeAddQueryModal]
  );

  return (
    <QueryLibraryContext.Provider value={contextVal}>
      {children}
      <QueryLibraryDrawer
        isOpen={isDrawerOpen}
        close={closeDrawer}
        activeDatasources={activeDatasources}
        queryActionButton={finalActionButton}
      />
      <AddToQueryLibraryModal
        isOpen={isAddQueryModalOpen}
        close={closeAddQueryModal}
        query={activeQuery}
        onSave={onSave}
        context={context}
      />
    </QueryLibraryContext.Provider>
  );
}
